import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// components
import CircularLoading from './Loading/CircularLoading';

// context
import AuthState from '../context/auth/AuthState';
import UserState from '../context/user/UserState';
import AuthContext from '../context/auth/authContext';
import DepartmentState from '../context/department/DepartmentState';
import MunicipalityState from '../context/municipality/MunicipalityState';
import PreventionState from '../context/prevention/PreventionState';
import LawState from '../context/law/LawState';
import TestimonialState from '../context/testimonial/TestimonialState';
import GameState from '../context/game/GameState';
import InstanceState from '../context/instance/InstanceState';
import ProcessState from '../context/process/ProcessState';
import UserProcessState from '../context/userProcess/userProcessState';
import DashboardState from '../context/dashboard/dashboardState';
import PartnerInstitutionState from '../context/partnerInstitution/partnerInstitutionState';

// loading
import 'react-loading-skeleton/dist/skeleton.css';

// config
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

// pages
const Layout = lazy(() => import('./Layout'));
const Login = lazy(() => import('../pages/login/Login'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const Error = lazy(() => import('../pages/error/Error'));

export default function App() {
  return (
    <PartnerInstitutionState>
      <DashboardState>
        <UserProcessState>
          <ProcessState>
            <InstanceState>
              <GameState>
                <TestimonialState>
                  <LawState>
                    <PreventionState>
                      <MunicipalityState>
                        <DepartmentState>
                          <UserState>
                            <AuthState>
                              <SnackbarProvider maxSnack={3}>
                                <BrowserRouter>
                                  <Switch>
                                    <Suspense
                                      fallback={
                                        <CircularLoading
                                          height={window.innerHeight}
                                        />
                                      }
                                    >
                                      <Route
                                        exact
                                        path="/"
                                        render={() => (
                                          <Redirect to="/app/home" />
                                        )}
                                      />
                                      <Route
                                        exact
                                        path="/app"
                                        render={() => (
                                          <Redirect to="/app/home" />
                                        )}
                                      />
                                      <PrivateRoute
                                        path="/app"
                                        component={Layout}
                                      />
                                      <PublicRoute
                                        path="/login"
                                        component={Login}
                                      />
                                      <Route
                                        exact
                                        path="/auth/forgotpassword"
                                        render={() => <ForgotPassword />}
                                      />
                                      <Route
                                        exact
                                        path="/auth/resetpassword/:slug"
                                        render={() => <ResetPassword />}
                                      />
                                    </Suspense>
                                    <Route render={() => <Error />} />
                                  </Switch>
                                </BrowserRouter>
                              </SnackbarProvider>
                            </AuthState>
                          </UserState>
                        </DepartmentState>
                      </MunicipalityState>
                    </PreventionState>
                  </LawState>
                </TestimonialState>
              </GameState>
            </InstanceState>
          </ProcessState>
        </UserProcessState>
      </DashboardState>
    </PartnerInstitutionState>
  );

  function PrivateRoute({ component, ...rest }) {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, loading, user, loadUser, logout } = authContext;

    useEffect(() => {
      loadUser();
      // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //   if (!loading && user?.data) {
    //     // Load processes for user operator
    //     if (user.data.role === 'operador') {
    //       getProcessByCurrentUser();
    //     }
    //   }
    // }, [loading]);

    // Check if user are blocked
    useEffect(
      () => {
        if (!loading) {
          if (user && user.data && !user.data.accessWeb) {
            logout();
          }
        }
      },
      // eslint-disable-next-line
      [loading]
    );

    return (
      <>
        {isAuthenticated &&
          loading === false &&
          user &&
          user.data &&
          user.data.accessWeb && (
            <Route
              {...rest}
              render={(props) => React.createElement(component, props)}
            />
          )}

        {!isAuthenticated && loading === false && (
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    from: props.location,
                  },
                }}
              />
            )}
          />
        )}
      </>
    );
  }

  function PublicRoute({ component, ...rest }) {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, loading, user, loadUser, logout } = authContext;

    useEffect(() => {
      loadUser();
      // eslint-disable-next-line
    }, []);

    // Check if user are blocked
    useEffect(
      () => {
        if (!loading) {
          if (user && user.data && !user.data.accessWeb) {
            logout();
          }
        }
      },
      // eslint-disable-next-line
      [loading]
    );

    return (
      <>
        {isAuthenticated &&
          loading === false &&
          user &&
          user.data &&
          user.data.accessWeb && (
            <Route
              {...rest}
              render={(props) => (
                <Redirect
                  to={{
                    pathname: '/',
                  }}
                />
              )}
            />
          )}

        <Route
          {...rest}
          render={(props) => React.createElement(component, props)}
        />
      </>
    );
  }
}
